.editorLinks
	&-button
		border-radius: 50%
		width: 1.8em
		height: 1.8em
		background: white
		border: 1px solid #e2e2ea
		margin-left: .3em
		font-size: .7rem
		cursor: pointer
		display: inline-flex
		align-items: center
		justify-content: center

		&:focus
			outline: none
			background: #f2f2f2
